var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            id: _vm.localId,
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "calendar-modal",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.state.isSubmitting
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            size: "sm",
                            variant: "success",
                            disabled: "",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                        ],
                        1
                      )
                    : !_vm.readOnly && (_vm.canAdd() || _vm.canEdit())
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.disableSave,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$t("calendar.working_week"))),
              ]),
              !_vm.isReadOnly && _vm.canEdit() && _vm.canDelete()
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn-action btn-lg",
                        attrs: { id: "BTN_RESET_" + _vm.localId },
                        on: { click: _vm.useBase },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["fad", "clock-rotate-left"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "BTN_RESET_" + _vm.localId,
                          triggers: "hover",
                          placement: "top",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("calendar.reset_week_time")) + " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "ul",
            { staticClass: "day-list mb-3" },
            [
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 1,
                  entry: _vm.calendarData["Monday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 2,
                  entry: _vm.calendarData["Tuesday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 3,
                  entry: _vm.calendarData["Wednesday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 4,
                  entry: _vm.calendarData["Thursday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 5,
                  entry: _vm.calendarData["Friday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 6,
                  entry: _vm.calendarData["Saturday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
              _c("WorkingDay", {
                attrs: {
                  readOnly: _vm.isReadOnly,
                  calendarName: _vm.calendarName,
                  dayIndex: 0,
                  entry: _vm.calendarData["Sunday"],
                },
                on: {
                  add: _vm.addEntry,
                  remove: _vm.removeEntry,
                  canSave: _vm.canSave,
                },
              }),
            ],
            1
          ),
          _c("ExceptionList", {
            attrs: {
              readOnly: _vm.isReadOnly,
              exceptions: _vm.exceptionData,
              calendarName: _vm.cName,
              baseStartHour: _vm.baseStartHour,
              baseEndHour: _vm.baseEndHour,
            },
            on: { change: _vm.exceptionChange, delete: _vm.exceptionDelete },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }